<section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
  <div>
    <h1 class="text-2xl mx-0 my-0 title">{{ 'messages.title' | translate }}</h1>
    <p class="mx-0 my-0 subtitle">{{ 'messages.subtitle' | translate }}</p>
  </div>
</section>
<section>
  @if (messages) {
    <p-table
      #dt
      [value]="messages"
      dataKey="uuid"
      [styleClass]="'pansa-table'"
      [scrollable]="true"
      scrollHeight="600px"
      [(selection)]="selectedMessages"
      [breakpoint]="'960px'"
      (onRowExpand)="onRowToggle($event)"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center lg:flex-row flex-column">
          <p-selectButton
            [options]="messageOptions"
            optionLabel="label"
            optionValue="value"
            [multiple]="true"
            (onChange)="filterByType($event)"
            [(ngModel)]="selectedFilterType"
            [allowEmpty]="false"
          ></p-selectButton>
          <div>
            <p-calendar
              [(ngModel)]="dateRange"
              dateFormat="yy-mm-dd"
              [iconDisplay]="'input'"
              [showIcon]="true"
              (onSelect)="filterByDate($event)"
              selectionMode="range"
              [readonlyInput]="true"
              #calendar
            >
              <ng-template pTemplate="footer">
                <div class="flex justify-content-between">
                  <p-button
                    [label]="'primeng.clear' | translate"
                    [text]="true"
                    icon="pi pi-times"
                    (click)="clearFilters()"
                  ></p-button>
                  <p-button
                    [label]="'buttons.filter' | translate"
                    icon="pi pi-check"
                    (click)="filterByDate()"
                    [disabled]="!dateRange"
                  ></p-button>
                </div>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="header">
            {{ 'messages.subject' | translate }} <p-sortIcon field="header"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="recvTime">
            {{ 'messages.date' | translate }} <p-sortIcon field="recvTime"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2 w-2"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-message let-expanded="expanded">
        <tr class="text-sm">
          <td>
            <div class="flex align-items-center">
              <p-tableCheckbox [value]="message"></p-tableCheckbox>
            </div>
          </td>
          <td class="p-1" [ngClass]="{ 'font-bold': !message.read }">
            <div class="flex align-items-center">
              <p-button
                [icon]="message.type === messageType.NOTIFICATION ? 'pi pi-bell' : 'pi pi-envelope'"
                [rounded]="true"
                [text]="true"
              ></p-button>
              {{ message.header }}
            </div>
          </td>
          <td class="p-1">{{ message.recvTime | date: 'yyyy-MM-dd HH:mm' }}</td>
          <td class="p-1">
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="message"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-message let-rowIndex="rowIndex">
        <tr class="text-xs">
          <td colspan="7" class="p-1">
            <p-progressBar
              mode="indeterminate"
              [style]="{ height: '6px' }"
              *ngIf="loadDetailsIdx === rowIndex"
            ></p-progressBar>
            <div class="p-3" [innerHTML]="message.details"></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="8">
            <p-button
              [label]="'buttons.delete' | translate"
              [disabled]="!selectedMessages.length"
              (click)="onRemoveSelected()"
              [text]="true"
              [plain]="true"
            >
              <rqa-icon iconName="trash" class="p-button-icon-left"></rqa-icon>
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</section>
