import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { TableModule, TableRowExpandEvent } from 'primeng/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TemplatesService } from 'src/app/services/templates.service';
import { LoadTemplate, Template } from 'src/app/model/template.model';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoadTemplateDialogComponent } from './load-template-dialog/load-template-dialog.component';
import * as moment from 'moment';
import { Router, RouterModule } from '@angular/router';
import { Route } from 'src/app/model/route.enum';
import { TooltipModule } from 'primeng/tooltip';
import { DateService } from 'src/app/services/date.service';
import { DialogManagerService } from 'src/app/services/dialog-manager.service';

@Component({
  selector: 'rqa-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    TableModule,
    TranslateModule,
    CalendarModule,
    SelectButtonModule,
    IconComponent,
    RouterModule,
    TooltipModule
  ]
})
export class TemplatesComponent implements OnInit {
  private templatesService = inject(TemplatesService);
  private messageService = inject(MessageService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private dateService = inject(DateService);
  private router = inject(Router);
  private dialogManager = inject(DialogManagerService);
  templates: Template[];
  selectedTemplates: Template[] = [];

  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.getTemplates();
  }

  onRemoveSelected(): void {
    const uuids: string[] = this.selectedTemplates.map((el) => el.uuid);
    this.templatesService.deleteTemplates(uuids).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.deletedTemplate'),
          life: 3000
        });
        this.templates = this.templates.filter((val) => !this.selectedTemplates?.includes(val));
        this.selectedTemplates = [];
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('notifications.deleteTemplateError'),
          life: 3000
        });
      }
    });
  }

  onEditTemplate(uuid: string): void {
    this.router.navigate([`${Route.TEMPLATES_EDIT}/${uuid}`]);
  }

  onLoadTemplate(template: Template): void {
    this.ref = this.dialogService.open(LoadTemplateDialogComponent, {
      header: this.translateService.instant('dialogs.loadTemplate.header'),
      contentStyle: { overflow: 'auto' },
      data: {
        name: template.name
      },
      width: '500px',
      baseZIndex: 10000
    });
    this.dialogManager.addDialog(this.ref);
    this.ref.onClose.subscribe((date) => {
      if (date) {
        const payload = {
          date: moment(date).format('YYYY-MM-DD').toString(),
          uuid: template.uuid
        };
        this.loadTemplate(payload);
      }
      this.dialogManager.removeDialog(this.ref);
    });
  }

  onRowToggle(event: TableRowExpandEvent): void {
    if (event.data && event.data.uuid) {
      this.getTemplateDetails(event.data.uuid);
    }
  }

  private loadTemplate(templateData: LoadTemplate): void {
    this.templatesService.loadTemplate(templateData).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          detail: res.value,
          life: 3000
        });
        this.router.navigate([Route.HOME]);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  private getTemplates(): void {
    this.templatesService.getTemplates().subscribe((res) => {
      this.templates = res;
    });
  }

  private getTemplateDetails(uuid: string): void {
    const templateIdx = this.templates.findIndex((template) => template.uuid === uuid);
    this.templatesService.getTemplate(uuid).subscribe((res) => {
      this.templates[templateIdx] = {
        ...this.templates[templateIdx],
        templateDetailsDTOS: res.templateDetailsDTOS.map((details) => ({
          ...details,
          startTime: this.dateService.convertUtcToLocalTime(details.startTime),
          endTime: this.dateService.convertUtcToLocalTime(details.endTime)
        }))
      };
    });
  }
}
