import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://sso-pansa.test.pansa.io/auth/',
  realm: 'webrqa-test',
  clientId: 'webrqa'
};

const center: number[] = [51, 19];
const siteId = 4984580;
export const environment = {
  production: false,
  keycloak: keycloakConfig,
  center,
  siteId
};
