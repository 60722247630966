import { Injectable, OnDestroy } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class DialogManagerService implements OnDestroy {
  private dialogRefs: DynamicDialogRef[] = [];

  constructor() {
    window.addEventListener('popstate', this.handlePopState);
  }

  addDialog(ref: DynamicDialogRef) {
    this.dialogRefs.push(ref);
  }

  removeDialog(ref: DynamicDialogRef) {
    this.dialogRefs = this.dialogRefs.filter((d) => d !== ref);
  }

  handlePopState = () => {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  };

  ngOnDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  }
}
